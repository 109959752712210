import { styled } from '@mui/system';
import { DARK_TEXT_COLOR } from '../../colors';

export const StyledSelectElement = styled('select')(`
  background-color: #d4daea;
  border-radius: 28.7px;
  border: none;
  color: ${DARK_TEXT_COLOR};
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding: 18px 28px;
  transition: all 300ms ease;
    -webkit-appearance: none;

  width:100%;
  &:hover {
  }

  &:focus {
    outline: none;
  }

   @media (max-width: 900px) {
    min-width: 60%;
  }
`);

export const StyledSelectOptionElement = styled('option')(`
  font-size: 16px;
  outline: none;
  font-weight: 400;
  border-radius: 28.7px;
  background-color: #fff;
  border: none;
  padding: 18px 28px;
  color: ${DARK_TEXT_COLOR};
  transition: all 300ms ease;

  &:hover {
  }

  &:focus {
    outline: none;
  }

   @media (max-width: 900px) {
    min-width: 60%;
  }
`);

// export const CustomSelect = React.forwardRef(function CustomInput(
//   props: React.SelectHTMLAttributes<HTMLInputElement>,
//   ref: React.ForwardedRef<HTMLInputElement>
// ) {
//   const { getRootProps, getInputProps } = useInput(props, ref);

//   return <StyledSelectElement {...props} {...getInputProps()} />;
// });
