import React from 'react';
import { IconSvg } from './icon-props';

export const IconMessage = (props: React.CSSProperties) => {
  return (
    <IconSvg
      viewBox="0 0 307.447 157.995"
      width={props.width || '307.447'}
      height={props.height || '157.995'}
      fill={props.fill || '#00a6dd'}
      style={{ ...props }}
    >
      <g id="Group_741" data-name="Group 741" transform="translate(-1047.67 -1097.02)">
        <g id="Group_739" data-name="Group 739" transform="translate(-1899.385 -8012.753)">
          <g id="Group_170" data-name="Group 170">
            <path
              id="Path_360"
              data-name="Path 360"
              d="M2969.975,9267.768a2.527,2.527,0,0,1-1.8-.715c-1.241-1.188-1.113-3.227.39-6.232,1.582-3.163,3.189-11.111,2.243-12.407-.833-.724-4.247-.829-6.99-.914a47.43,47.43,0,0,1-8.043-.707c-5.938-1.249-7.311-16.437-7.876-45.9l-.041-2.134c-.065-3.226-.242-6.41-.413-9.488-.65-11.689-1.211-21.784,4.555-28.073,6.974-7.607,30.433-6.3,33.732-6.079,2.482-.158,38.368-2.44,57.172-3.309,19.9-.915,26.119,3.406,29.329,10.184,2,4.214,2.32,20.4,2.585,44.607.078,7.157.152,13.917.319,19.183.6,18.9-15.033,20.134-23.432,20.8-.994.079-1.925.152-2.761.244-2.768.308-9.27.408-17.5.533-12.57.189-35.969.545-38.7,2.321-2.125,1.954-17.349,15.646-20.513,17.4A4.691,4.691,0,0,1,2969.975,9267.768Zm8.955-108.858c-8.459,0-20.255.928-23.978,4.99-4.627,5.047-4.109,14.362-3.51,25.147.173,3.114.352,6.334.418,9.631l.041,2.139c.145,7.553,1.344,17.757,2.137,26.453,1.3,14.259,2.5,15.574,2.6,15.618a44.34,44.34,0,0,0,7.31.613c4.073.126,7.59.234,9.539,1.94,3.615,3.164.028,13.854-1.1,16.62,4.457-3.525,13.819-11.81,17.72-15.385,2.558-2.345,14.8-2.92,41.28-3.321,7.8-.119,14.542-.222,17.121-.508.874-.1,1.847-.175,2.888-.257,11.067-.873,20.191-2.73,19.748-16.682-.168-5.307-.242-12.087-.321-19.266-.181-16.544-2.951-39.2-4.721-42.938-2.129-4.5-5.851-8.813-25.53-7.9-19.35.893-54.336,3.289-54.713,3.313l-.134.009-.134-.01C2983.942,9159,2981.6,9158.909,2978.93,9158.909Z"
              fill="#15284c"
            />
            <path
              id="Path_361"
              data-name="Path 361"
              d="M2990.893,9211.748a6.147,6.147,0,0,1-1.013-.083,6.4,6.4,0,0,1-5.712-3.651,8.011,8.011,0,0,1,.4-7.985,8.666,8.666,0,0,1,8.887-2.58c1.2.428,3.956,1.925,3.754,6.579-.134,3.078-1.081,5.294-2.817,6.59A5.8,5.8,0,0,1,2990.893,9211.748Zm-.348-4.027a1.967,1.967,0,0,0,1.481-.329c.69-.536,1.111-1.793,1.187-3.538.1-2.21-.807-2.532-1.1-2.639a4.821,4.821,0,0,0-4.341,1.214,4.135,4.135,0,0,0-.022,3.8,2.419,2.419,0,0,0,2.341,1.442h.246Z"
              fill="#15284c"
            />
            <path
              id="Path_362"
              data-name="Path 362"
              d="M3012.457,9211.669a6.731,6.731,0,0,1-2.784-.621,8.011,8.011,0,0,1-4.72-6.453,8.668,8.668,0,0,1,5.277-7.6c1.2-.42,4.285-1,7.059,2.744,1.835,2.475,2.5,4.793,1.964,6.892a5.968,5.968,0,0,1-2.843,3.657A6.453,6.453,0,0,1,3012.457,9211.669Zm-.425-10.994a1.373,1.373,0,0,0-.475.091,4.833,4.833,0,0,0-2.607,3.677,4.139,4.139,0,0,0,2.375,2.962,2.415,2.415,0,0,0,2.727-.354l.191-.153.2-.094a1.969,1.969,0,0,0,.943-1.189c.2-.851-.267-2.091-1.307-3.494C3013.187,9200.92,3012.485,9200.675,3012.032,9200.675Z"
              fill="#15284c"
            />
            <path
              id="Path_363"
              data-name="Path 363"
              d="M3033.063,9210.4a6.723,6.723,0,0,1-2.783-.621,8.012,8.012,0,0,1-4.722-6.454,8.667,8.667,0,0,1,5.277-7.6c1.2-.42,4.285-1,7.06,2.745,1.834,2.474,2.495,4.792,1.964,6.891a5.958,5.958,0,0,1-2.844,3.657A6.442,6.442,0,0,1,3033.063,9210.4Zm-.426-10.994a1.362,1.362,0,0,0-.476.091,4.832,4.832,0,0,0-2.6,3.676,4.132,4.132,0,0,0,2.375,2.963,2.417,2.417,0,0,0,2.727-.354l.19-.154.2-.093a1.965,1.965,0,0,0,.943-1.189c.2-.852-.267-2.092-1.307-3.494C3033.792,9199.651,3033.09,9199.407,3032.637,9199.407Z"
              fill="#15284c"
            />
          </g>
        </g>
        <g id="Group_740" data-name="Group 740" transform="translate(-1899.385 -8012.753)">
          <g id="Group_172" data-name="Group 172">
            <path
              id="Path_364"
              data-name="Path 364"
              d="M3241.467,9187.287a4.013,4.013,0,0,1-1.684-.422c-2.393-1.116-13.612-9.9-14.807-10.832-2.4-.934-17.7-.08-25.944.383-5.6.313-10.03.562-11.951.484-.565-.021-1.191-.026-1.861-.031-5.936-.045-16.985-.129-17.514-13.574-.141-3.571-.416-8.154-.708-13-.993-16.494-1.554-27.535-.358-30.608,1.941-4.983,6.078-8.376,19.959-8.722,12.69-.317,36.844-.5,38.86-.516,2.414-.334,18.357-2.324,23.633,2.665,4.406,4.17,4.514,11.16,4.64,19.256.033,2.089.066,4.248.177,6.424l.074,1.429c1.223,23.5.385,31.009-3.585,32.145a32.724,32.724,0,0,1-5.524.888,17.617,17.617,0,0,0-4.215.727c-.264,1.354,1.189,5.977,2.127,7.563,1.326,2.236,1.485,3.858.489,4.96A2.383,2.383,0,0,1,3241.467,9187.287Zm-22.648-15.668c4.981,0,7.49.365,8.542,1.2,2.455,1.947,7.478,5.8,10.872,8.247-1.169-2.97-2.577-7.767-.526-9.836,1.4-1.412,5.261-1.457,8.138-1.751a31.162,31.162,0,0,0,4.659-.707c2.192-2.584-.185-21.993-.515-28.348l-.074-1.432c-.115-2.248-.15-4.443-.183-6.567-.111-7.19-.208-13.4-3.389-16.412-3.012-2.85-14.145-2.488-20.455-1.591l-.267.02c-.257,0-24.6.938-37.748,1.266-12.978.325-15.184,3.23-16.332,6.176-.968,2.485-1.184,17.64-.55,28.165.293,4.873.57,9.476.712,13.086.351,8.924,6.086,9.676,13.547,9.733.716.006,1.386.011,1.989.035,1.735.063,6.29-.187,11.569-.482C3207.937,9171.911,3214.366,9171.619,3218.819,9171.619Zm6.056,4.334,0,0Zm15.821-2.113-15.09-61.4h0Z"
              fill="#00a6dd"
            />
            <path
              id="Path_365"
              data-name="Path 365"
              d="M3208.555,9155.243a2.028,2.028,0,0,1-1.977-1.663c-.046-.246-1.015-6.086,6.9-10.817,4.118-2.462,7.17-6.78,6.951-9.831-.117-1.631-2.279-3.229-4.361-4.1-7.125-2.969-11.479,3.917-11.707,4.194a2,2,0,0,1-3.1-2.521c2.565-3.17,9.514-8.29,17.442-4.983,3.462,1.442,5.493,3.972,5.721,7.118.373,5.186-4.189,10.742-8.889,13.554-5.393,3.226-5.023,6.567-5,6.71a2,2,0,0,1-1.965,2.336Z"
              fill="#00a6dd"
            />
            <path
              id="Path_366"
              data-name="Path 366"
              d="M3209.4,9165.183a3.863,3.863,0,0,1-3.713-2.959l-.073-.3.04-.307c.212-1.666.9-2.211,2.837-3a2.73,2.73,0,0,1,2.916.543,3.97,3.97,0,0,1,1.093,3.895,2.9,2.9,0,0,1-2.381,2.071A4.437,4.437,0,0,1,3209.4,9165.183Zm-2.091-3.354h0Z"
              fill="#00a6dd"
            />
          </g>
        </g>
      </g>
    </IconSvg>
  );
};
