import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid, Theme, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { CAPTIONS_TEXT_COLOR } from '../../colors';
import { onReadyCaptcha } from '../../services/verify-captcha';
import { sendContactDetails } from '../../services/zapier-services';
import { loadRecaptchaScript, removeRecaptchaScript } from '../../utils/script-loader';
import { isAlphaAndSpace, isUrlPresent } from '../../utils/validators';
import { StyledSelectElement, StyledSelectOptionElement } from '../inputs/select-dropdwon';
import { CustomTextArea } from '../inputs/text-area';
import { CustomInput } from '../inputs/text-field';
import { ErrorMessage } from '../primitives/error-message';
import { FlexBox } from '../primitives/flex';
import { PrimaryButton } from '../primitives/primary-button';

const initialValues = {
  enquiryType: '',
  fullName: '',
  email: '',
  subject: '',
  phone: '',
  message: ''
};

export interface ContactUsFormValues {
  enquiryType: string;
  fullName: string;
  email: string;
  subject: string;
  phone?: string;
  message?: string;
}
export interface ContactUsFormProps {
  location?: any;
  theme: Theme;
}

export default function ContactUsForm(props: ContactUsFormProps) {
  const { theme } = props;
  const [formValues, setFormValues] = useState<ContactUsFormValues>(initialValues);
  const [errorValues, setErrorValues] = useState<ContactUsFormValues>(initialValues);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submittedStatus, setSubmittedStatus] = useState<'pending' | 'success' | 'fail'>('pending');
  const { enquiryType, fullName, email, subject, phone, message } = formValues;
  const formRef = useRef(null);

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    let result = validateForm(formValues);
    const { isValidForm, errorValues } = result;
    if (!isValidForm) {
      setErrorValues(errorValues);
    } else {
      onReadyCaptcha(
        async () => {
          const response = await sendContactDetails(formValues);
          if (response.status === 'success') {
            setSubmittedStatus('success');
            setFormValues(initialValues);
          } else setSubmittedStatus('fail');
          setIsSubmitting(false);
        },
        ({ error }) => {
          setIsSubmitting(false);
          window.alert('Captcha Verification Failed');
          console.log({ error });
        }
      );

      console.log('Valid');
    }
  };

  const settingFormValue = (obj: { value: string; field: string }) => {
    const { value, field } = obj;
    setFormValues((formValues) => ({ ...formValues, [field]: value }));
  };

  const validateForm = (formValues: ContactUsFormValues) => {
    let isValid = true;
    let error = { ...errorValues };
    Object.keys(formValues).map((key) => {
      let value = formValues[key];

      if (key != 'message' && key != 'phone' && isEmpty(value)) {
        error[key] = '*Mandatory field';
        isValid = false;
      } else if (isUrlPresent(value)) {
        error[key] = '*URL not allowed';
        isValid = false;
      } else {
        error[key] = '';
      }
    });
    console.log('LINE:87', { isValidForm: isValid, errorValues: error });

    if (!isValid) {
      setIsValidForm(isValid);
      return { isValidForm: isValid, errorValues: error };
    }

    Object.keys(formValues).map((key) => {
      let value = formValues[key];
      switch (key) {
        case 'fullName':
        case 'subject':
          if (!isAlphaAndSpace(value)) {
            error[key] = '*Only allowed alphabets';
            isValid = false;
          }
          break;
        case 'email':
          if (!isEmail(value)) {
            error.email = '*Invalid email';
            isValid = false;
          }
          break;
        case 'phone':
          if (value && !isMobilePhone(value, ['en-AU'])) {
            error.phone = '*Invalid phone number';
            isValid = false;
          }
          break;
      }
    });
    setIsValidForm(isValid);
    console.log({ isValidForm: isValid, errorValues: error });
    return { isValidForm: isValid, errorValues: error };
  };

  const handleChange = (obj: { value: string; field: string }) => {
    const { value, field } = obj;

    let error = { ...errorValues };
    validateForm({ ...formValues, [field]: value });
    if (field != 'message' && field != 'phone' && isEmpty(value)) {
      error[field] = '*Mandatory field';
      settingFormValue(obj);
      setErrorValues(error);
      return;
    }
    if (isUrlPresent(value)) {
      error[field] = '*URL not allowed';
      settingFormValue(obj);
      setErrorValues(error);
      return;
    }
    error[field] = '';

    switch (field) {
      case 'fullName':
        error.fullName = !isAlphaAndSpace(value) ? '*Only allowed alphabets' : '';
        break;
      case 'email':
        error.email = !isEmail(value) ? '*Invalid email' : '';
        break;
      case 'subject':
        error.subject = !isAlphaAndSpace(value) ? '*Invalid email' : '';
        break;
      case 'phone':
        error.phone = value && !isMobilePhone(value, ['en-AU']) ? '*Invalid phone number' : '';
        break;
    }

    setErrorValues(error);
    settingFormValue(obj);
  };

  useEffect(() => {
    // load recaptcha script
    const script = loadRecaptchaScript();

    // check if it comes from another form
    if (props.location.search) {
      const parsed = new URLSearchParams(props.location.search);
      setFormValues((formValues) => ({
        ...formValues,
        fullName: parsed.get('name'),
        enquiryType: 'realEstateAgent'
      }));

      // window scroll to contact-us form
      let elementPosition = formRef.current.getBoundingClientRect().top;
      let offsetPosition = elementPosition - 200;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }

    return () => {
      removeRecaptchaScript(script);
    };
  }, []);

  return (
    <form ref={formRef} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledSelectElement
            onChange={(e) => handleChange({ value: e.target.value, field: 'enquiryType' })}
            value={enquiryType}
          >
            <StyledSelectOptionElement selected value="">
              Select Enquiry Type*
            </StyledSelectOptionElement>
            <StyledSelectOptionElement value="general">General Enquiry </StyledSelectOptionElement>
            <StyledSelectOptionElement value="realEstateAgent">
              Real Estate Agent Enquiry
            </StyledSelectOptionElement>
          </StyledSelectElement>
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.enquiryType}
          </ErrorMessage>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="First and Last Name*"
            onChange={(e) => handleChange({ value: e.target.value, field: 'fullName' })}
            value={fullName}
            style={{ width: '100%' }}
          />

          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.fullName}
          </ErrorMessage>
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Email*"
            value={email}
            onChange={(e) => handleChange({ value: e.target.value, field: 'email' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.email}
          </ErrorMessage>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Subject*"
            value={subject}
            onChange={(e) => handleChange({ value: e.target.value, field: 'subject' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.subject}
          </ErrorMessage>
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Phone"
            value={phone}
            onChange={(e) => handleChange({ value: e.target.value, field: 'phone' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.phone}
          </ErrorMessage>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomTextArea
            placeholder="Message"
            value={message}
            onChange={(e) => handleChange({ value: e.target.value, field: 'message' })}
            minRows={12}
            maxRows={13}
            style={{ width: '100%' }}
          />
          <ErrorMessage theme={theme} padding="10px 18px 0px">
            {errorValues.message}
          </ErrorMessage>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography padding="10px 18px 0px" color={CAPTIONS_TEXT_COLOR}>
            *Mandatory
          </Typography>
        </Grid>
      </Grid>
      <Box textAlign="center">
        {submittedStatus === 'pending' ? (
          <PrimaryButton type="submit" variant="contained" disabled={!isValidForm || isSubmitting}>
            {!isSubmitting ? 'Submit' : 'Submitting...'}
          </PrimaryButton>
        ) : submittedStatus === 'success' ? (
          <FlexBox width="fit-content" mx="auto">
            <CheckCircleIcon color="success" sx={{ mr: '10px' }} />
            <Typography>
              Thanks for contacting us. A member of the Listing Loop team will be in touch shortly.
            </Typography>
          </FlexBox>
        ) : (
          <Typography color="red">Something went wrong</Typography>
        )}
      </Box>
    </form>
  );
}
