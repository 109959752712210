export const BuyerProfileImage = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fbuyer-profile.png?alt=media&token=28975de3-3d59-41e8-9640-248bc777ff96`;

export const ImageAboutUs = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fabout_us_header.jpeg?alt=media&token=b611ea0e-97d5-4fd5-abe7-55a989df0b85`;

export const ImageAboutUsBanner = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fabout-us-banner.png?alt=media&token=4848f536-d47d-4130-b933-8f1a99e75bf1`;

export const ImageForAgentsBanner = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Ffor-agents-banner.png?alt=media&token=5d36b622-d7f5-4d7b-8249-1f1208fda115`;

export const ImageAppStore = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fapp-store.png?alt=media&token=810bb807-a738-4c6c-ae52-de72a4ac4285`;

export const ImageBuyerFirst = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2FListing%20Loop%20puts%20buyers%20first.jpg?alt=media&token=8326090c-7c2b-4123-8a35-313934ad4c14`;

export const ImageBuyersBanner = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fbuyers-banner.png?alt=media&token=183c31d4-12c1-4b84-9005-6b09584881f7`;

export const ImageCareers = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2FCareers_header_image.jpeg?alt=media&token=67f73a49-5403-47d4-906d-b3cad8821172`;

export const ImageCareersBg = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fcareers.jpeg?alt=media&token=eb52fc35-36ed-470c-b525-52a9a757ffcf`;

export const ImageContactHelpline = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2FJ00593-0091%20(1).jpeg?alt=media&token=97793020-5520-4b19-a0bd-b8a713a2d68d`;

export const ImageGetStarted = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fget_started.png?alt=media&token=a46c3c14-885c-46ef-a150-191d73d22167`;

export const ImageGetIntoLoop = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fget-in-the-loop.png?alt=media&token=68d3dc35-9406-4da3-b33e-247b1de77f40`;

export const ImageGooglePlayStore = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fgoople-play-store.png?alt=media&token=c1af7363-19e7-45e7-8a45-a029850d1964`;

export const ImageHomePageHeader = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fhome-page-banner.png?alt=media&token=409b6f32-ca18-4938-b812-d8eed581702a`;

export const ImageHouse = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2FWe%20invite%20you%20to%20get%20in%20the%20loop.jpg?alt=media&token=e6a0194a-9a53-4a7f-9704-820f8452e06c`;

export const ImageHyperTargeted = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fhyper-targeted.png?alt=media&token=c47b66f6-19ce-4fd1-86ce-2087bd177d66`;

export const ImageListingLoopForAgents = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Flisting-loop-for-agents.png?alt=media&token=e5d1b1f4-4725-4f5e-9403-cc6fcab0584c`;

export const ImageListingLoopForBuyers = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Flisting-loop-for-buyers.png?alt=media&token=4fc8b73b-d3d5-4c55-aed8-e94f435cb265`;

export const ImageMobile = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fmobile.png?alt=media&token=ee04daf7-1c78-4331-b781-c49c0a86ad12`;

export const ImageMoreListings = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fmore-listings.png?alt=media&token=dd7e8b97-6c91-4a86-8853-0650ecf6bb82`;

export const ImageOfficeLocationMobile = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Foffice-location.png?alt=media&token=d575a6a8-4b30-442f-b41b-4070e5fc0faa`;

export const ImageRewardForHelpingBuyers = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Freward-for-helping-buyers.png?alt=media&token=2245d21b-2223-4816-bff6-58a8482aa4c3`;

export const ImageSearchProperty = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fsearch-property.jpeg?alt=media&token=b1d71b5e-6198-474f-93c1-f3ffa6e12eae`;

export const ImageStartedWithListingLoop = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fstarted-with-listing-loop.png?alt=media&token=2a6639fc-47c0-4600-b9e6-e482457f3e30`;

export const ImageWorkForYou = `https://firebasestorage.googleapis.com/v0/b/listing-loop-sandbox.appspot.com/o/ll-marketing%2Fwork-for-you.png?alt=media&token=4675bd34-ab62-4725-9701-0ab3d9b2f9be`;
