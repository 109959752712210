import { RECAPTCHA_SITE_KEY } from '../constants';
import firebase from 'gatsby-plugin-firebase';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const verifyCaptcha = async (token: string) => {
  const secret = '6LckFYkdAAAAALBG9UbcfMeIDGcLuo5NRS9NbMuI';
  try {
    let verify = firebase
      .app()
      .functions('australia-southeast1')
      .httpsCallable('httpsCallableCaptchaVerify');
    let response = await verify({ token });
    return JSON.parse(response.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const onReadyCaptcha = (
  onSuccess: () => void,
  onError: (error: { error: string }) => void
) => {
  window.grecaptcha.ready(async function () {
    window.grecaptcha
      .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
      .then(async function (token) {
        console.log({ token });

        let response = await verifyCaptcha(token);
        console.log({ response });

        console.log({ env: process.env });
        if (response?.success) {
          onSuccess();
        } else {
          onError({ error: 'Captcha Verification Failed' });
          console.log({ response });
        }
      });
  });
};
