import firebase from 'gatsby-plugin-firebase';

export const sendContactDetails = async (zapierData: any) => {
  try {
    let myRequest = firebase
      .app()
      .functions('australia-southeast1')
      .httpsCallable('httpsSendDataToZapier');
    let response = await myRequest({ dataType: 'contactDetails', data: zapierData });
    console.log({ response: response });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const subscribeNewsLetter = async (email: string) => {
  try {
    let myRequest = firebase
      .app()
      .functions('australia-southeast1')
      .httpsCallable('httpsSendDataToZapier');
    let response = await myRequest({ dataType: 'newsLetter', data: { email } });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
