import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import { IconMarker } from '../assets/icons/contact-us/marker';
import { CurveWithArrow } from '../assets/icons/curve-with-arrow';
import { IconWave } from '../assets/icons/how-it-works/wave';
import { IconMessage } from '../assets/icons/message';
import { ImageContactHelpline, ImageOfficeLocationMobile } from '../assets/images';
import { LL_BACKGROUND_COLOR } from '../colors';
import { DecorativeHeading } from '../components/decorative-heading';
import ContactUsForm from '../components/forms/contact-us-form';
import Layout from '../components/layout';
import { Absolute } from '../components/primitives/absolute';
import { FlexBox } from '../components/primitives/flex';
import { LLBox } from '../components/primitives/ll-box';
import { Width80Box } from '../components/primitives/Width80Box';
import { theme } from '../theme';

export interface ContactUsProps {
  location?: any;
}

const officeAddress = {
  suburb: 'Mornington',
  title: '1 Fuji Crescent Mornington Victoria 3931'
};

const enquiryData = {
  general: '1300 MY LOOP (1300 695 667)',
  media: 'media@listingloop.com.au',
  investors: 'investors@listingloop.com.au'
};

const EnquiryBox = ({ heading, value }: { heading: string; value: string; theme: Theme }) => {
  return (
    <Box sx={{ marginBottom: '30px' }}>
      <Typography
        color="primary.main"
        fontFamily="Tropiline"
        fontSize="inherit"
        fontWeight="inherit"
        sx={{
          [theme.breakpoints.down('md')]: {
            textAlign: 'center'
          }
        }}
      >
        {heading}
      </Typography>
      <Typography
        fontSize="inherit"
        sx={{
          [theme.breakpoints.down('md')]: {
            textAlign: 'center'
          }
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

const ContactUs = (props: ContactUsProps) => {
  const { suburb, title } = officeAddress;
  console.log({ location: props.location });
  return (
    <Box>
      <Width80Box
        sx={{
          paddingY: '100px',
          paddingTop: '200px',
          paddingX: '20px',
          marginX: 'auto',
          [theme.breakpoints.down('md')]: {
            paddingTop: '100px',
            paddingBottom: '30px'
          }
        }}
      >
        <DecorativeHeading text="Let’s get in touch We’re really nice and friendly" />
      </Width80Box>
      <Width80Box paddingBottom={{ md: '60px', xs: '30px' }}>
        <FlexBox
          px="20px"
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column'
            }
          }}
        >
          <LLBox flex="42.5%">
            <img src={ImageOfficeLocationMobile} height="auto" width="100%" />
          </LLBox>
          <Box flex="15%" position="relative">
            <Absolute
              sx={{
                transform: 'rotate(90deg)',
                [theme.breakpoints.down('md')]: { display: 'none' }
              }}
            >
              <CurveWithArrow color={theme.palette.primary.main} />
            </Absolute>
          </Box>
          <FlexBox
            flex="42.5%"
            alignItems="center"
            flexGrow={1}
            sx={{
              fontSize: '28px',
              fontWeight: 500,
              [theme.breakpoints.down('md')]: {
                fontSize: '22px',
                justifyContent: 'center'
              }
            }}
          >
            <Box
              paddingTop="30px"
              sx={{
                maxWidth: '340px',
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%'
                }
              }}
            >
              <Box
                sx={{
                  [theme.breakpoints.down('md')]: {
                    textAlign: 'center'
                  }
                }}
              >
                <IconMarker />
              </Box>
              <Typography
                color="primary.main"
                fontFamily="Tropiline"
                fontSize="inherit"
                fontWeight="inherit"
                marginBottom="10px"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    textAlign: 'center'
                  }
                }}
              >
                {suburb}
              </Typography>
              <Typography
                fontSize="inherit"
                fontWeight="inherit"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    textAlign: 'center'
                  }
                }}
              >
                {title}
              </Typography>
            </Box>
          </FlexBox>
        </FlexBox>
      </Width80Box>
      <IconWave width="100%" height="auto" marginBottom="-5px" />
      <Box bgcolor={LL_BACKGROUND_COLOR}>
        <Width80Box>
          <FlexBox
            paddingY={{ md: '60px', xs: '30px' }}
            px="20px"
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column-reverse'
              }
            }}
          >
            <Box
              flex="42.5%"
              flexGrow={1}
              sx={{
                justifyContent: 'center',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'flex-start'
                }
              }}
            >
              <Box
                sx={{
                  maxWidth: '400px',
                  marginX: 'auto',
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '100%',
                    marginTop: '20px'
                  }
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight={500}
                  mb={'40px'}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      textAlign: 'center'
                    }
                  }}
                >
                  Need a direct line?
                </Typography>
                <Box
                  sx={{
                    fontSize: '28px',
                    fontWeight: 500,
                    [theme.breakpoints.down('md')]: {
                      fontSize: '22px'
                    }
                  }}
                >
                  <EnquiryBox
                    theme={theme}
                    heading="General enquiries"
                    value={enquiryData.general}
                  />
                  <EnquiryBox theme={theme} heading="Media enquiries" value={enquiryData.media} />
                  <EnquiryBox theme={theme} heading="Investors" value={enquiryData.investors} />
                </Box>
              </Box>
            </Box>
            <Box flex="15%"></Box>
            <LLBox flex="42.5%" flexGrow={1} position="relative">
              <Absolute top="11%" left="36%" height="34%">
                <IconMessage height="100%" width="auto" />
              </Absolute>
              <img
                src={ImageContactHelpline}
                height="auto"
                width="100%"
                style={{ maxHeight: '400px', objectFit: 'cover', objectPosition: 'center' }}
              />
            </LLBox>
          </FlexBox>
        </Width80Box>
        <DecorativeHeading text="Write us if you have any questions" />
        <Box
          sx={{
            paddingY: '60px',
            paddingX: '20px',
            width: '60%',
            marginX: 'auto',
            [theme.breakpoints.down('md')]: {
              width: '100%'
            }
          }}
        >
          <ContactUsForm theme={theme} location={props.location} />
        </Box>
        <Box bgcolor={LL_BACKGROUND_COLOR} width="100%">
          <Box position="relative">
            <Absolute
              height="250px"
              width="100%"
              zIndex={-1}
              bgcolor={LL_BACKGROUND_COLOR}
            ></Absolute>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ContactUs.Layout = Layout;
ContactUs.isNavbarBackgroundEmpty = true;
export default ContactUs;
