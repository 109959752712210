import React from 'react';
import { IconSvg } from '../icon-props';

export const IconWave = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '57.963'}
      height={props.height || '1599.861'}
      viewBox="0 0 1599.861 57.963"
      fill={props.fill}
      style={{ ...props }}
    >
      <path
        id="Path_1081"
        data-name="Path 1081"
        d="M5384.408,1627.589l-.011-57.963s-98.422,40.948-427.375,39c-278.9-1.653-400.293-37.048-665.834-37.048s-506.641,51.215-506.641,51.215v4.8Z"
        transform="translate(-3784.547 -1569.626)"
        fill="#f1f3f4"
      />
    </IconSvg>
  );
};
