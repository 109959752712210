import React from 'react';
import { IconSvg } from '../icon-props';

export const IconMarker = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '47.752'}
      height={props.height || '77.239'}
      viewBox="0 0 47.752 77.239"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_161" data-name="Group 161" transform="translate(-2855.099 -8476.787)">
        <path
          id="Path_330"
          data-name="Path 330"
          d="M2879.736,8554.026a2.032,2.032,0,0,1-1.685-.9c-.13-.192-3.215-4.769-5.664-8.9-.267-.45-.677-1.1-1.2-1.93-4.3-6.847-15.711-25.036-16.08-39.094-.414-15.783,9.9-23.648,18.859-25.586,10.617-2.3,18.342.161,23.616,7.519,6.209,8.663,6.919,18.873,2.169,31.214-4.5,11.707-17.681,35.612-18.24,36.624a2.03,2.03,0,0,1-1.689,1.049C2879.8,8554.026,2879.766,8554.026,2879.736,8554.026Zm1.367-73.173a29.776,29.776,0,0,0-6.273.737c-7.44,1.61-16.214,5.911-15.864,19.208.34,12.947,11.533,32.75,15.668,39.339.542.864.97,1.547,1.25,2.02,1.206,2.036,2.6,4.223,3.686,5.9,3.732-6.889,11.707-24.093,15.195-33.159,4.278-11.116,4.941-19.821-.483-27.389A15.148,15.148,0,0,0,2881.1,8480.854Z"
          fill="#061e3b"
        />
        <path
          id="Path_331"
          data-name="Path 331"
          d="M2879.289,8518.2c-7.5,0-12.206-5.88-12.433-6.171-.03-.038-.059-.077-.086-.117-4.657-6.9-4.157-12.335-2.917-15.679a16.246,16.246,0,0,1,11.2-9.614c8.82-2.414,16.282,1.28,19.48,9.636a15.955,15.955,0,0,1-.9,12.9,16.374,16.374,0,0,1-10.423,8.519A15.072,15.072,0,0,1,2879.289,8518.2Zm-9.2-8.634c.52.63,5.215,6.02,12.066,4.178a12.35,12.35,0,0,0,7.856-6.438,12.053,12.053,0,0,0,.728-9.6c-2.459-6.422-10.3-7.65-14.1-6.979-4.148.731-7.74,3.579-8.977,6.918C2866.368,8501.145,2867.208,8505.265,2870.091,8509.569Z"
          fill="#061e3b"
        />
      </g>
    </IconSvg>
  );
};
