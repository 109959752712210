import React from 'react';

interface CurveWithArrowProps {
  color: string;
  height?: number | string;
  width?: number | string;
}

export const CurveWithArrow = (props: CurveWithArrowProps) => {
  const { color, height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '97.276'}
      height={height || '186.761'}
      viewBox="0 0 97.276 186.761"
      color={color}
    >
      <g id="Group_341" data-name="Group 341" transform="translate(-248.366 -709.847)">
        <path
          id="Path_670"
          data-name="Path 670"
          d="M315.931,892.483c-24.059-9.313-44.072-23.191-55.486-41.834-12-19.6-14.763-43.387-9.6-68.461a49.459,49.459,0,0,1,16.93-28.487c-16.746-19.167-13.972-43.551-13.934-43.854l3.968.5-1.984-.248,1.985.246c-.142,1.152-2.617,23.356,13.077,40.937,12.4-8.967,29.426-14.611,44.605-14.244,17.114.432,28.609,8.11,29.909,16.557a17.271,17.271,0,0,1-7.47,17.505c-13.724,9.635-37.194,4.494-54.367-4.873a57.97,57.97,0,0,1-13.027-9.59,45.564,45.564,0,0,0-15.774,26.362c-9.838,47.787,13.413,87.592,62.2,106.476ZM273.71,754.2a55.368,55.368,0,0,0,11.768,8.515c22.327,12.178,41.277,11.341,50.154,5.11a13.282,13.282,0,0,0,5.815-13.623c-.965-6.267-12.292-11.084-26.934-11.453C300.368,742.391,285.3,745.995,273.71,754.2Z"
          fill="currentColor"
        />
        <path
          id="Path_671"
          data-name="Path 671"
          d="M304.152,896.608a2,2,0,0,1-.362-3.966l10.98-2.033-4.853-9.706a2,2,0,0,1,.9-2.683c.987-.494,2.863,2.705,3.358,3.7l6.555,11.659-16.206,3A2,2,0,0,1,304.152,896.608Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
